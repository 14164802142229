import React from "react";
import {
  ScreenIcon,
  ShapesIcon,
  StopwatchIcon,
  SystemsModernizationIcon,
  SchemaDesignSessionsIcon,
  ApolloGraphqlIcon,
  DigitalStrategyAndArchitectureShape,
  TestersIcon,
} from "@xolvio/xolvio-ui";

export const pageData = {
  newFirstSection: {
    subheading: "WHAT WE DO",
    header: "Boutique API, backend, & SDLC expertise",
    content:
      "The last thing you want is to build legacy systems of tomorrow. At Xolvio, we help you modernize your APIs and backend systems, as well as supercharge your delivery life cycle. As a result, we empower your engineering organization to deliver more business value faster, and for years to come.",
  },
  tout: {
    heading: "Our capabilities",
    content: "Services to help you solve for the future, today.",
  },
  firstSection: {
    imagePosition: "right",
    subheading: "DEVELOPMENT",
    heading: "Custom Software Development",
    headingHighlights: "Custom",
    description:
      "We practice what we preach and apply our #QualityFaster delivery approach for a range of custom software " +
      "development. Our team creates highly scalable software that stands the test of time. We work very quickly and " +
      "do not cut corners. The code we product typically has over 95% automated test coverage.",
    description2:
      "Whether you're looking to create a proof-of-concept or build an entire new capability, we can help you get " +
      "there at speed and with high quality.",
    icon: <ScreenIcon />,
    url: "/services/custom-software-development",
  },
  secondSection: {
    imagePosition: "left",
    subheading: "CONSULTING",
    heading: "Apollo GraphQL Consulting",
    headingHighlights: "GraphQL",
    description:
      "We have been a part of Apollo's journey since the very beginning, and we have deep and wide expertise that we " +
      "offer to clients on an hourly consulting basis. Whether you want to create a proof-of-concept for you using " +
      "best practices, or to use us a sounding board for your foray into this great technology, we're here for you.",
    description2:
      "We can help in all areas related to Apollo GraphQL and surrounding technologies including Apollo Federation, " +
      "One-graph unification, Domain Driven Design, µService deployment, automated testing, security, performance, and more.",
    icon: <ShapesIcon />,
    url: "/services/apollo-graphql-consulting",
  },
  thirdSection: {
    imagePosition: "right",
    subheading: "COACHING",
    heading: "Continuous Delivery Coaching",
    headingHighlights: "Continuous",
    description:
      "Our coaching service help you unlock your potential and unleash your ability to scale. We do this by analyzing " +
      "and mapping out your current practices using our in-house #QualityFaster model. We then define " +
      "tried-and-tested solutions and changes to your process based, and we coach your team(s) to implement these changes.",
    description2:
      "We have made it our business to help organizations modernize their technologies stacks and processes to gain " +
      "speed, stability, and quality improvements, especially in enterprises where legacy systems seem impossible to deal with.",
    icon: <StopwatchIcon />,
    url: "/services/continuous-delivery-coaching",
  },
  servicesDigitalExperienceIntegration: {
    title: "Digital Experience Integration",
    subheading: "SERVICES",
    sections: [
      {
        heading: "Apollo GraphQL Consulting",
        description:
          "Accelerate and scale GraphQL adoption with help from the official Apollo professional services partner.",
        icon: <SchemaDesignSessionsIcon />,
        url: "/services/apollo-graphql-professional-services/",
        sectionId: "service1",
      },
      {
        heading: "Experience Graph Managed Services",
        description:
          "Replace your API headaches with a single, federated GraphQL API to power all of your digital experiences—the Experience Graph.",
        icon: <ApolloGraphqlIcon />,
        url: "/services/experience-graph-managed-services/",
        sectionId: "service2",
      },
      {
        heading: "GraphQL Security Review & Remediation",
        description:
          "Leverage GraphQL application security automation from Escape combined with our vulnerability remediation professional services.",
        icon: <TestersIcon />,
        url: "/services/graphql-security-review-remediation",
        sectionId: "service3",
      },
    ],
  },
  servicesAcceleratedSoftwareTransformation: {
    title: "Accelerated Software Transformation",
    subheading: "SERVICES",
    sections: [
      {
        heading: "SDLC & Architecture Consulting",
        description:
          "Let us audit your SDLC and software architecture to provide detailed recommendations on how to improve scalability, performance, and development velocity.",
        icon: <DigitalStrategyAndArchitectureShape />,
        url: "/services/sdlc-architecture-consulting",
        sectionId: "service4",
      },
      {
        heading: "Rapid Backend Development",
        description:
          "We build highly complex, infinitely scalable, GraphQL-native backends 400% faster than any other vendor.",
        icon: <StopwatchIcon />,
        url: "/services/rapid-backend-development",
        sectionId: "service5",
      },
      {
        heading: "Legacy Systems Modernization",
        description:
          "Migrate even the most complex monolithic systems to future-proof solutions and regain control of delivery.",
        icon: <SystemsModernizationIcon />,
        url: "/services/systems-modernization",
        sectionId: "service6",
      },
    ],
  },
  defaultTextToutFullWidthForServicesProps: {
    heading: "Rapidly build complex, high-quality software",
    subheading: "HOW WE WORK",
    content:
      "Refined and perfected over more than a decade now, our Quality Faster methodology innovates on custom software development by combining collaborative requirements facilitation, software quality best practices, and malleable architecture expertise.",
    buttonText: "Find out more",
    buttonUrl: "/how-we-work",
  },
  defaultTextServicesContactSectionProps: {
    heading: "Pre-engagement consultation workshop",
    subheading: "HOW TO GET STARTED",
    content:
      "Witness the impact of visual requirements facilitation and our problem solving prowess in a 90 min. consulting session.",
    buttonText: "Request consultation",
    buttonUrl: "/contact-us",
  },
};
