import React from "react";
import styled from "styled-components";
import {
  ButtonPrimaryLink,
  CenteredContentWrapper,
  colors,
  ConfettiBigSectionWrapper,
  device,
  EmphasizedText,
  Hero,
  ImageConfettiWrapper,
  SectionHeader,
  spacing,
  Spacing,
  Subheading,
  TextBody,
  TextToutFullwidth,
  ToutGroup,
  TwoColumnContentNodeWrapper,
  TwoColumnsWithImage,
  TwoColumnsWrapper
} from "@xolvio/xolvio-ui";
import { OtherServices } from "./OtherServices";
import { contactUsFooterProps } from "../../pages-content/homepage";
import BackgroundImage from "gatsby-background-image";

export const ServicePageTemplate = ({
  pageData,
  data,
  additionalNodeAtTheBottom
}) => (
  <Wrapper>
    <ConfettiBigSectionWrapper
      hideMobileConfetti
      style={{
        backgroundColor: "#ffffff",
        height: "220px",
        marginBottom: "30px",
        zIndex: 100
      }}
    >
      <HeaderContentWrapper>
        {pageData.header.icon}
        <Subheader>{pageData.header.subheading}</Subheader>
        <Header style={{ maxWidth: "100% !important", textAlign: "center" }}>
          {pageData.header.heading}
        </Header>
        <HeaderBody>{pageData.header.description}</HeaderBody>
      </HeaderContentWrapper>
    </ConfettiBigSectionWrapper>

    <Hero
      inline
      height={"693px"}
      heading={
        <InlineHeroWithTwoColumnsHeader
          style={{
            position: "absolute",
            textAlign: "left"
          }}
        >
          <EmphasizedText
            text={pageData.firstSection.header}
            highlight={pageData.firstSection.headerHighlights}
          />
        </InlineHeroWithTwoColumnsHeader>
      }
      children={
        <HeroContentWrapper>
          <div></div>

          <HeroContent>
            <TextBody bold>{pageData.firstSection.listItemBold}</TextBody>
            <TextBody>{pageData.firstSection.listItemRegular}</TextBody>
          </HeroContent>
        </HeroContentWrapper>
      }
    />

    <TwoColumnsWithImage
      imagePosition={"left"}
      imageNode={
        <ImageConfettiWrapper
          style={{ zIndex: 100 }}
          elementWithBackgroundImage={
            <BackgroundImage
              fluid={data.yachts.childImageSharp.fluid}
              durationFadeIn={200}
            />
          }
        />
      }
      contentNode={
        <TwoColumnContentNodeWrapper>
          <Subheading>{pageData.secondSection.subheading}</Subheading>
          <TwoColumnSectionHeader>
            <EmphasizedText
              highlight={pageData.secondSection.headerHighlights}
              text={pageData.secondSection.header}
            />
          </TwoColumnSectionHeader>
          <Text>{pageData.secondSection.content}</Text>
        </TwoColumnContentNodeWrapper>
      }
    />

    <SpacingSmall />

    <TwoColumnsWithImage
      imagePosition={"right"}
      imageNode={
        <img
          src={
            pageData.thirdSection.image ||
            "https://www.qualityfaster.com/static/focus-on-quality-cc9c6fe2b58ef7c685009d11dfe249ab.svg"
          }
          width="500px"
        />
      }
      contentNode={
        <TwoColumnContentNodeWrapper>
          <Subheading>{pageData.thirdSection.subheading}</Subheading>
          <TwoColumnSectionHeader>
            <EmphasizedText
              highlight={pageData.thirdSection.headerHighlights}
              text={pageData.thirdSection.header}
            />
          </TwoColumnSectionHeader>
          <Text>{pageData.thirdSection.content}</Text>
        </TwoColumnContentNodeWrapper>
      }
    />

    <ToutGroup {...pageData.toutGroupSet1} />

    <ToutGroup {...pageData.toutGroupSet2} />

    <StyledButtonPrimaryLink>
      <ButtonPrimaryLink>
        {pageData.buttonBeforeOtherServices.text}
      </ButtonPrimaryLink>
    </StyledButtonPrimaryLink>

    <SpacingSmall />
    {additionalNodeAtTheBottom && additionalNodeAtTheBottom}

    <OtherServices services={pageData.otherServices} />

    <TextToutFullwidth
      {...contactUsFooterProps}
      buttonUrl={pageData.contactUsUrl}
    />
  </Wrapper>
);

const Wrapper = styled.div``;

const HeaderContentWrapper = styled.div`
  flex: 1;
  max-width: 1000px;
  text-align: center;
  justify-content: center;
  margin-bottom: 25px;

  > * {
    text-align: center;
    justify-content: center;
    display: flex;
    margin: auto;
    width: 100%;
  }
  @media ${device.tablet} {
    text-align: left;
    margin-bottom: 65px;
  }
`;

const HeaderBody = styled(TextBody)`
  max-width: 750px !important;
  text-align: center !important;
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: -0.2px;
`;

const Subheader = styled(Subheading)`
  margin-top: ${spacing.mobile.padding.default}px;
`;

const Header = styled(SectionHeader)`
  max-width: calc(100% - 150px);
  @media ${device.tablet} {
    text-align: center;
    max-width: 750px;
  }
`;

export const SpacingBigger = styled(Spacing)`
  height: ${spacing.mobile.betweenSections}px;
  width: 100%;

  @media ${device.tablet} {
    height: ${props =>
      spacing.desktop.betweenSections + (props.extraSpace || 60)}px;
  }
`;

export const InlineHeroWithTwoColumnsHeader = styled.div`
  @media ${device.mobile} {
    text-align: start;
    max-width: 100%;
    & > div {
      width: 100%;
    }
  }
  @media ${device.tabletVertical} {
     position: absolute;
   }
  @media ${device.desktop} {
    width: 460px;
    max-width: 50%;
    position: absolute;
    margin-top: -25px;
  }
`;

export const HeroContentWrapper = styled(TwoColumnsWrapper)`
  @media ${device.mobile} {
    padding: 0 !important;
  }

  > * {
    width: 100%;
  }

  @media ${device.tabletVertical} {
    height: 350px;
    > * {
      width: 100%;
    }
  }
  @media ${device.desktop} {
    > * {
      width: 50%;
    }
  }
`;

export const HeroContent = styled.div`
  height: auto;
  margin: 0 auto;

  & > p {
    text-align: start;
  }
  
  > * {
    color: ${colors.TextBlack};
    text-align: left;
  }

  @media ${device.mobile} {
    margin-top: 0;
    & > p {
      margin-left: auto;
      margin-right: auto;
      text-align: left;
    }
  }

  @media ${device.tabletVertical} {
    margin-top: ${props => (`${props.marginTablet || 50}px`)};
  }
  
  @media ${device.desktop} {
    margin-top: ${props => (`${props.margin || 50}px`)};
  }
`;

export const TwoColumnSectionHeader = styled(SectionHeader)`
  @media ${device.tabletVertical} {
    margin: 20px 0 0;
  }
`;

const Text = styled(TextBody)`
  margin: 16px 0 24px;
`;

export const SpacingSmall = styled.div`
  height: 100px;
`;

const StyledButtonPrimaryLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
