import {
  colors,
  device,
  EmphasizedText,
  PageHeader,
  TextBody,
  spacing,
  ConfettiBigSectionWrapper,
  TextToutFullwidth,
  Services as ServicesComponent,
  ImageConfettiWrapper,
  Subheading,
  Header,
  TwoColumnsWithImage,
  TwoColumnContentNodeWrapper,
} from "@xolvio/xolvio-ui";
import React from "react";
import styled from "styled-components";
import { graphql, StaticQuery } from "gatsby";
import { pageData } from "../pages-content/services";
import BackgroundImage from "gatsby-background-image";
import { ButtonPrimaryLink } from "@xolvio/xolvio-ui";
import { TwoColumnSectionHeader } from "./Services/ServicePageTemplate";

const Page = ({ data }) => (
  <>
    <ToutWrapper>
      <ToutContent>
        <ToutHeader>
          <EmphasizedText text={pageData.tout.heading} highlight={"your way"} />
        </ToutHeader>
        <ToutTextBody style={{ textAlign: "center" }}>
          {pageData.tout.content}
        </ToutTextBody>
      </ToutContent>
    </ToutWrapper>

    <MoveHack top="25px" topMobile={"100px"} />

    <TwoColumnsWithImage
      imagePosition={"right"}
      imageNode={
        <E2eImageWrapper>
          <img src={"/assets/images/end-to-end-delivery.svg"} width="500px" />
        </E2eImageWrapper>
      }
      contentNode={
        <TwoColumnContentNodeWrapper>
          <Subheading>{pageData.newFirstSection.subheading}</Subheading>
          <TwoColumnSectionHeader>
            <EmphasizedText
              highlight="aaaaaaaaa"
              text={pageData.newFirstSection.header}
            />
          </TwoColumnSectionHeader>
          <Text>{pageData.newFirstSection.content}</Text>
        </TwoColumnContentNodeWrapper>
      }
    />

    <MoveHack top={"100px"} topMobile={'80px'} />

    <ServicesWrapper>
      <ServicesComponent
        {...pageData.servicesDigitalExperienceIntegration}
        id="services"
      />
    </ServicesWrapper>

    <ServicesWrapper>
      <ServicesComponent
        {...pageData.servicesAcceleratedSoftwareTransformation}
        id="services"
      />
    </ServicesWrapper>

    <Approach>
      <ImageConfettiWrapper
        elementWithBackgroundImage={
          <BackgroundImage fluid={data.brainstorm.childImageSharp.fluid} />
        }
      />
      <ApproachContent>
        <Subheading>
          {pageData.defaultTextToutFullWidthForServicesProps.subheading}
        </Subheading>
        <Header style={{ width: "70%" }}>
          Rapidly build complex,<br />
          <EmphasizedText
            highlight="high-quality"
            text="high-quality software"
          />
        </Header>
        <TextBody>
          {pageData.defaultTextToutFullWidthForServicesProps.content}
        </TextBody>
        <MoveHack topMobile={"25px"} />
        <ButtonPrimaryLink
          href={pageData.defaultTextToutFullWidthForServicesProps.buttonUrl}
          as={"a"}
        >
          {pageData.defaultTextToutFullWidthForServicesProps.buttonText}
        </ButtonPrimaryLink>
      </ApproachContent>
    </Approach>
    <TextToutFullwidth {...pageData.defaultTextServicesContactSectionProps} />
  </>
);

export const Services = () => (
  <StaticQuery
    query={graphql`
      query {
        approach: file(
          relativePath: { eq: "two_bearded_men_pointing_at_laptop.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        brainstorm: file(relativePath: { eq: "people_talking_brainstorming.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        yachts: file(relativePath: { eq: "sailing_yachts.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      return <Page data={data} />;
    }}
  />
);

export default Services;

const sectionSpacingMobile = "80px";
const sectionSpacingDesktop = "180px";

export const Spacing = styled.div`
  height: ${spacing.mobile.betweenSections}px;
  width: 100%;

  @media ${device.tablet} {
    height: ${spacing.desktop.betweenSections}px;
  }
`;

export const ExtraSpacing = styled.div`
  @media ${device.desktop} {
    height: ${spacing.mobile.betweenSections}px;
  }
`;

export const ToutWrapper = styled(ConfettiBigSectionWrapper)`
  padding-top: 55px;
`;

export const ToutHeader = styled(PageHeader)`
  &&& {
    background-color: ${colors.AcceptanceGreenUltimatelyLight};
  }
  @media ${device.tabletVertical} {
    margin-bottom: 0;
    * {
      text-align: center;
    }
  }
`;

export const ToutTextBody = styled(TextBody)`
  font-size: 20px;
  @media ${device.tabletVertical} {
    margin-bottom: 50px;
  }
`;

export const ToutContent = styled.div`
  * {
    max-width: 750px !important;
  }

  @media ${device.tabletVertical} {
    * {
      text-align: center;
    }
  }
`;

const ServicesWrapper = styled.div`
  > div > div {
    padding-bottom: calc(${sectionSpacingMobile} + 120px);
    margin-bottom: calc(-${sectionSpacingMobile} - 40px);

    @media ${device.tabletVertical} {
      margin-top: calc(${sectionSpacingMobile});
      margin-bottom: calc(-${sectionSpacingMobile} - 90px);
    }

    @media ${device.desktop} {
      margin-bottom: calc(-${sectionSpacingDesktop});
    }
  }

  > div > div > div > div > div > svg {
    height: 100px;
    width: 100px;
  }
`;

const Approach = styled.div`
  margin-top: 50px;
  margin-bottom: 100px;
  margin-left: 0;
  display: flex;
  flex-direction: row;
  flex: 1;
  @media (min-width: 1400px) {
    margin-left: 150px;
  }
  > * {
    flex: 1;
  }
  > div:first-child {
    display: none;
    @media (min-width: 1400px) {
      display: flex;
    }
  }
  > div:nth-child(2) {
    align-items: center;
    @media (min-width: 1400px) {
      align-items: flex-start;
    }
  }
`;

const ApproachContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 50px 20px;
  h1 {
    text-align: center;
  }
  @media ${device.mobile} {
    margin-top: 100px;
  }
  @media (min-width: 1400px) {
    margin: 50px 100px;
    h1 {
      text-align: start;
    }
  }
`;

const Text = styled(TextBody)`
  margin: 16px 0 24px;
  text-align: left;
`;

export const MoveHack = styled.div`
  margin-top: ${(props) => props.top};

  @media ${device.mobile} {
    margin-top: ${(props) => props.topMobile};
  }

  @media ${device.phone} {
    margin-top: ${(props) => props.topPhone};
  }
`;

const E2eImageWrapper = styled.div`
  @media ${device.mobile} {
    img {
      width: 100%;
    }
  }
`;
