import styled from "styled-components";
import React from "react";
import {
  colors,
  ButtonInlineLink,
  CenteredContentWrapper,
  device,
  ScreenSmallIcon,
  StopwatchSmallIcon,
  breakpoints,
  spacing,
  DigitalStrategyIcon,
  ApolloGraphqlIcon,
  BespokeMartechIcon,
  EndToEndIcon,
  HeadlessEcommerceIcon,
  SolutionIdeationIcon,
  StaffAugmentationIcon,
  SystemsModernizationIcon,
  DigitalStrategyAndArchitectureShape,
  StopwatchIcon,
  TestersIcon,
  SchemaDesignSessionsIcon,
} from "@xolvio/xolvio-ui";

const otherServices = [
  {
    icon: <DigitalStrategyIcon />,
    url: "/services/technical-strategy-and-architecture",
    name: "Technical Strategy & Architecture",
  },
  {
    icon: <SolutionIdeationIcon />,
    url: "/services/solution-ideation-and-validation",
    name: "Solution Ideation & Validation",
  },
  {
    icon: <SystemsModernizationIcon />,
    url: "/services/systems-modernization",
    name: "Systems Modernization",
  },
  {
    icon: <ApolloGraphqlIcon />,
    url: "/services/apollo-graphql-professional-services",
    name: "Apollo GraphQL Professional Services",
  },
  {
    icon: <EndToEndIcon />,
    url: "/services/end-to-end-delivery",
    name: "End-to-end Delivery",
  },
  {
    icon: <StaffAugmentationIcon />,
    url: "/services/staff-augmentation",
    name: "Staff Augmentation",
  },
  {
    icon: <BespokeMartechIcon />,
    url: "/services/bespoke-martech-solutions",
    name: "Bespoke Martech Solutions",
  },
  {
    icon: <HeadlessEcommerceIcon />,
    url: "/services/headless-ecommerce-solutions",
    name: "Headless E-commerce Solutions",
  },
  {
    icon: <ScreenSmallIcon />,
    url: "/services/custom-software-development",
    name: "Custom development",
  },
  {
    icon: <StopwatchSmallIcon />,
    url: "/services/continuous-delivery-coaching",
    name: "Continuous Delivery Coaching",
  },
  {
    icon: <SchemaDesignSessionsIcon />,
    url: "/services/apollo-graphql-professional-services/",
    name: "Apollo GraphQL Consulting",
  },
  {
    icon: <ApolloGraphqlIcon />,
    url: "/services/experience-graph-managed-services/",
    name: "Experience Graph Managed Services",
  },
  {
    icon: <TestersIcon />,
    url: "/services/graphql-security-review-remediation",
    name: "GraphQL Security Review & Remediation",
  },
  {
    icon: <DigitalStrategyAndArchitectureShape />,
    url: "/services/sdlc-architecture-consulting",
    name: "SDLC & Architecture Consulting",
  },
  {
    icon: <StopwatchIcon />,
    url: "/services/rapid-backend-development",
    name: "Rapid Backend Development",
  },
  {
    icon: <SystemsModernizationIcon />,
    url: "/services/systems-modernization",
    name: "Legacy Systems Modernization",
  },
];

export const OtherServices = ({ services }) => (
  <OtherServicesWrapper>
    <OtherServicesHeader>Check our other services:</OtherServicesHeader>
    {otherServices
      .filter((service) => services.includes(service.name))
      .map((service) => (
        <ServiceLinkWrapper key={service.name}>
          <ServiceLink>
            <IconWrapper>{service.icon}</IconWrapper>

            <ButtonInlineLink as="a" href={service.url}>
              <ServiceHeader style={{ color: colors.AcceptanceGreen }}>
                {service.name}
              </ServiceHeader>
            </ButtonInlineLink>
          </ServiceLink>
        </ServiceLinkWrapper>
      ))}
  </OtherServicesWrapper>
);

const OtherServicesWrapper = styled(CenteredContentWrapper)`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 80px auto;

  @media ${device.tablet} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 48px;

    margin: 100px auto;
    max-width: 1000px;
  }

  @media ${device.desktop} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: ${breakpoints.desktop
      .start}px) and (max-width: ${breakpoints.desktop.end}px) {
    padding-left: ${spacing.mobile.padding.default}px;
  }
`;

const OtherServicesHeader = styled.h1`
  text-align: center;
  font-size: 24px;
  min-width: 265px;
  margin-top: 5px;
  font-family: HK Grotesk;
  font-weight: bold;
  letter-spacing: -0.2px;
  color: ${colors.TextBlack};

  @media ${device.tablet} {
    width: 265px;
    text-align: left;
  }
`;

const IconWrapper = styled.div`
  svg {
    height: 80px;
    width: 80px;
  }
`;

const ServiceLinkWrapper = styled.div`
  display: flex;
  width: 100%;

  @media ${device.tablet} {
    width: auto;
    margin-left: 0;
  }

  @media ${device.desktop} {
    margin-left: 15px;
  }
`;

const ServiceHeader = styled.div`
  margin-top: -3px;
  font-family: HK Grotesk;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.23px;
  @media ${device.mobile} {
    text-align: start;
  }

  @media ${device.tabletVertical} {
    margin-top: auto;
    font-size: 18px;
    font-weight: normal;
  }
`;

const ServiceLink = styled.div`
  display: flex;
  margin: 10px auto auto 70px;
  align-items: center;
  > * {
    margin-right: 15px;
  }

  @media ${device.tabletVertical} {
    margin: auto auto auto 140px;
  }

  @media ${device.tablet} {
    margin: auto 0;
  }

  @media ${device.desktop} {
    margin: auto;
  }
`;
